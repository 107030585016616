import { LOCALE_ID, importProvidersFrom, TransferState } from '@angular/core'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment, GIT_REVISION_TAG } from '@eliq/client/environments/environment'
import { BrowserModule } from '@angular/platform-browser'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics'

import { CacheService } from '@eliq/data-access'
import { CookieService } from '@eliq/data-access/cookie'
import {
	CachingInterceptor,
	EliqCurrencyPipe,
	EliqDatePipe,
	EliqNumberPipe,
	ImgSrcPipe,
	LanguageService,
	TranslateOrFallbackPipe,
} from '@eliq/core'
import { EliqApiHttpClient } from '@eliq/data-access'
import { EnvironmentService } from '@eliq/data-access'
import { FlowService } from '@eliq/feature/integrationless/flow/flow-service/flow.service'
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { CONFIG, DEBUG_MODE } from '@angular/fire/compat/analytics'
import { WindowRef } from '@eliq/core/WindowRef'
import { HeaderModule } from '@eliq/feature'
import {
	DatePipe,
	DecimalPipe,
	CurrencyPipe,
	CommonModule,
} from '@angular/common'
import {
	TranslateLoader,
	TranslateModule,
	TranslatePipe,
	TranslateService,
	TranslateStore,
} from '@ngx-translate/core'
import { NgxPopperjsModule } from 'ngx-popperjs'
import { HttpClientModule } from '@angular/common/http'
import { SnackbarModule } from '@eliq/ui'
import { ModalService } from '@eliq/ui/modal'
import { MatDialogModule } from '@angular/material/dialog'
import { MatCommonModule } from '@angular/material/core'
import { translationLoaderFactory } from '@eliq/data-access/services/translation-loader/translation-loader.service'
import { TokenInterceptorService } from '@eliq/feature/auth/interceptors/token-interceptor.service'

// Added for SMR
import { MatMenuModule } from '@angular/material/menu'
import { MatAutocompleteModule } from '@angular/material/autocomplete'

import { APP_INITIALIZER } from '@angular/core'
import { MatSelectModule } from '@angular/material/select'
import { MatInputModule } from '@angular/material/input'

export function appInitializerFactory(
	translate: TranslateService,
	languageService: LanguageService,
) {
	return () => {
		return new Promise((resolve) => {
			languageService.setCurrentLanguage().subscribe((chosenLang) => {
				translate.setDefaultLang(chosenLang)
				resolve(true)
			})
		})

		//return translate.use('es').toPromise()
	}
}

export const baseConfig = {
	providers: [
		{
			provide: CONFIG,
			useValue: {
				send_page_view: false,
			},
		},
		{ provide: DEBUG_MODE, useValue: !environment.production },
		{
			provide: LOCALE_ID,
			useValue: environment.locales[0] ?? environment.defaultLang.split('-')[0],
		},
		importProvidersFrom(
			BrowserModule,
			MatCommonModule,
			SnackbarModule,
			MatDialogModule,
			HttpClientModule,
			CommonModule,
			MatAutocompleteModule,
			MatSelectModule,
			MatInputModule,
			MatMenuModule,
		),
		/*CookieService,
		CacheService,
		ModalService,
		EliqApiHttpClient,
		FlowService,
		WindowRef,
		TranslateService,
		TranslateStore,*/
		DatePipe,
		ImgSrcPipe,
		CurrencyPipe,
		EliqCurrencyPipe,
		DecimalPipe,
		EliqNumberPipe,
		EliqDatePipe,
		TranslateOrFallbackPipe,
		TranslatePipe,
		importProvidersFrom(
			AngularFireModule.initializeApp(environment.firebase),
			AngularFireAnalyticsModule,
			TranslateModule.forRoot({
				loader: {
					provide: TranslateLoader,
					//useFactory: HttpLoaderFactory,
					useFactory: translationLoaderFactory,
					deps: [
						TransferState,
						HttpClient,
						'clientId',
						'baseUrl',
						'ASSETS_BASE_URL',
						EnvironmentService,
					],
				},
			}),
			NgxPopperjsModule.forRoot(),
		),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptorService,
			multi: true,
		},
		// Temporarily disabled 2023-10-25
		{ provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },

		// Disabling to prevent randomly generated cost values in SandboxInterceptor (they're good for demo purposes, but not for real clients)
		//{ provide: HTTP_INTERCEPTORS, useClass: SandboxInterceptor, multi: true },
		{ provide: 'LOCAL_STORAGE_AUTH', useValue: true },
		{ provide: 'IS_WEB_COMPONENT', useValue: false },
		{ provide: 'ASSETS_BASE_URL', useValue: '' },
		{
			provide: 'integrationless',
			useValue: (environment as any)['integrationless'],
		},
		{ provide: 'baseUrl', useValue: environment.eliqApiBaseUrl },
		{ provide: 'featureFlags', useValue: environment.featureFlags },
		{ provide: 'GIT_REVISION_TAG', useValue: GIT_REVISION_TAG },
		{ provide: 'clientId', useValue: environment.clientId },
		{ provide: 'clientName', useValue: environment.clientName },
		{ provide: 'defaultLang', useValue: environment.defaultLang },
		{ provide: 'isUAT', useValue: environment.uat },
		{ provide: 'isProd', useValue: environment.production },
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, LanguageService],
			multi: true,
		},
	],
}
