import { Injectable } from '@angular/core'
import { EliqApiHttpClient, JsonGetterService } from '@eliq/data-access'
import { BehaviorSubject, Observable, of, throwError } from 'rxjs'
import { catchError, map, switchMap, take, tap } from 'rxjs/operators'
import {
	AdviceStatusNumber,
	AdviceStatus,
	APIAdvice,
} from '../models/api-advice.model'
import { CoreDataStoreService, UnitType } from '@eliq/core'
import { Location as ModelsLocation } from '@eliq/models'
import { ConfigEnergyAdvisor } from '@eliq/data-access/services/config/models/config-energy-advisor.model'

export class AdviceMap {
	constructor(
		private _allAdvices: APIAdvice[],
		private _configEnergyAdvisor : ConfigEnergyAdvisor,
		public usedLocation: ModelsLocation,
	) {}

	get allAdvices() {
		// Only show advices that have yearly savings
		// TODO: this might be changed later to show them but hide the "you'll save x ..." text or similar?
		return this._allAdvices.filter(
			(a) => a.estimated_yearly_savings_cost >= 1 || a.estimated_yearly_savings_energy >= 1,
		)
	}
	setAdviceStatus(adviceId: string, newStatus: AdviceStatus) {
		const adviceIndex = this._allAdvices.findIndex((a) => a.id === adviceId)
		if (adviceIndex < 0) {
			throw new Error(`Could not find advice with id ${adviceId}`)
		}
		this._allAdvices[adviceIndex].status = newStatus
	}
	get newAdvices() {
		return this.allAdvices.filter((a) => a.status === AdviceStatus.None || a.status === AdviceStatus.SaveForLater)
	}
	get todoAdvices() {
		let advices = this.allAdvices.filter(
			(a) =>
				a.status === AdviceStatus.SaveForLater ||
				a.status === AdviceStatus.None,
		)

		if (this._configEnergyAdvisor?.maxTodoCardsCount)
			advices = advices.slice(0, this._configEnergyAdvisor.maxTodoCardsCount);

		return advices;
	}
	get doneAdvices() {
		return this.allAdvices.filter((a) => a.status === AdviceStatus.Implemented)
	}
	get discardedAdvices() {
		return this.allAdvices.filter((a) => a.status === AdviceStatus.NotRelevant)
	}
	getAdvicesWithStatus(status: AdviceStatus) {
		return this.allAdvices.filter((a) => a.status === status)
	}
	getAdvicesWithStatuses(statuses: AdviceStatus[]) {
		return this.allAdvices.filter((a) => statuses.includes(a.status))
	}
}

@Injectable({
	providedIn: 'root',
})
export class EnergyAdvisorApiService {
	private configEnergyAdvisor: ConfigEnergyAdvisor;

	constructor(
		private http: EliqApiHttpClient,
		private jsonGetterService : JsonGetterService,
		private coreDS: CoreDataStoreService,
	) {
		jsonGetterService.getEnergyAdvisorConfig().subscribe((config) => {
			if (config) {
				this.configEnergyAdvisor = config;
				return;
			}

			console.error("Failed to get Energy Advisor Config");
		})
	}
	
	public adviceMap$ = new BehaviorSubject<AdviceMap | null>(null)

	public getAPIAdvices(
		locId: number,
		unit: UnitType = 'cost',
	): Observable<APIAdvice[]> {
		return this.http
			.get<APIAdvice[]>(`/v3/locations/${locId}/advices?unit=${unit}&limit=100`)
			.pipe(
				map((advices) => {
					return advices.map((advice) => {
						advice?.estimated_savings && (advice.estimated_savings *= 10)
						return advice
					})
				}),
			)
	}

	public getAdviceMap(
		unit: UnitType,
		location?: ModelsLocation,
	): Observable<AdviceMap> {
		return this.adviceMap$.pipe(
			switchMap((currentAdvices) => {
				if (currentAdvices) { // if this.adviceMap$ has a non-null value, return it. We could also have used this.adviceMap$.getValue() and return this.adviceMap$.asObservable() but this is more readable.
					return of(currentAdvices)
				} else {
					return of(location).pipe(
						switchMap((loc) => {
							if (!loc) {
								return this.coreDS.getActiveLocation()
							}
							return of(loc as ModelsLocation)
						}),
						switchMap((loc) => {
							if (!loc) {
								return throwError(
									() =>
										new Error(
											'Could not get advices because there is no active location.',
										),
								)
							}
							return this.getAPIAdvices(loc.id as number, unit).pipe(
								map((advices) => new AdviceMap(advices, this.configEnergyAdvisor, loc as ModelsLocation)),
							)
						}),
						tap((adviceMap) => {
							this.adviceMap$.next(adviceMap)
						}),
						catchError((e, _c) => {
							console.error(e)
							return throwError(
								() =>
									new Error('Something went wrong in energy-advisor-api.service.ts'),
							)
						}),
					)
				}
			})
		)

	}

	public postAdviceStatus(
		locId: number,
		adviceId: string,
		newAdviceStatus: AdviceStatus,
	) {
		/* from: https://docs.google.com/presentation/d/19AYF9VchWW9iTTxa9axeCXXPuRH0Bp95s3i9xNo2BPU/edit#slide=id.g11380cec3f8_0_34
    {
        "feedback": {{feedback}},
        "advice_status": {{advice_status_code}},
        "additional_information": {{additional_information}}
    }
    */
		return this.getAdviceMap('energy').pipe(
			take(1),
			tap(adviceMap => {
				if (adviceMap) {
					adviceMap.setAdviceStatus(adviceId, newAdviceStatus)
					this.adviceMap$.next(adviceMap) // Set advice status and re-emit the adviceMap. We wont get into an infinite loop because of the take(1).
				} else {
					console.log("DEBUG postAdviceStatus updatedAdvices is null")
				}
			}),
			switchMap(_ => this.http.post(
				`/v3/locations/${locId}/advices/${adviceId}/feedback`,
				{
					advice_status: AdviceStatusNumber[newAdviceStatus],
				},
			))
		)


	}
}
